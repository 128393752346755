<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import Preloader from "@/components/widgets/preloader";
import EventBus from "../../../event-bus";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import CustomCKEditor from "ckeditor5-custom-build/build/ckeditor";

import MessageMenu from './MessageMenu'

import MessageAttachments from './MessageAttachments'
import MessageRecipientList from "./MessageRecipientList";

import Repository from "@/app/repository/repository-factory";
import Vue from "vue";
import Swal from "sweetalert2";

const MessageRepository = Repository.get("MessageRepository");

/**
 * Email-read component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Preloader,
    MessageMenu,
    MessageAttachments,
    MessageRecipientList,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: 'Wiadomości',
      items: [
        {
          text: 'Wiadomości',
          href: '/',
        },
        {
          text: 'Edycja',
          active: true,
        },
      ],
      showModal: false,
      preloader: true,
      messageData: {},
      editor: CustomCKEditor,
    }
  },
  created() {
    this.preloader = true;
    this.getMessageDetails();
    EventBus.$on('MessageRecipientsChanged', () => {
      this.getOnlyMessageRecipients();
    });
    EventBus.$on('MessageAttachmentsChanged', () => {
      this.getOnlyMessageAttachments();
    });
  },
  methods: {
    sendMessage() {
      if (this.messageData.title === '' || this.messageData.content === '') {
        Swal.fire({
          title: 'Błąd',
          text: 'Nie możesz wysłać wiadomości z pustym tytułem lub bez treści.',
          type: 'error',
          confirmButtonText: 'Ok',
        });
        return;
      }

      if (this.messageData.title === 'Tytuł wiadomości') {
        Swal.fire({
          title: 'Błąd',
          text: 'Nie możesz wysłać wiadomości z domyślnym tytułem. Popraw tytuł i spróbuj ponownie.',
          type: 'error',
          confirmButtonText: 'Ok',
        });
        return;
      }

      if (this.messageData.title.length < 10) {
        Swal.fire({
          title: 'Błąd',
          text: 'Tytuł wiadomości musi składać się z minimum 10 znaków.',
          type: 'error',
          confirmButtonText: 'Ok',
        });
        return;
      }

      Swal.fire({
        title: "Wysyłanie wiadomości",
        text: "Wiadomość zostanie wysłana do jej odbiorców i nie będzie można jej zmienić. Czy na pewno powinna być wysłana?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Tak, wyślij wiadomość!",
        cancelButtonText: "Anuluj"
      }).then(result => {
        if (result.value) {
          let payload = {
            title: this.messageData.title,
            content: this.messageData.content,
            label: 'info',
            version: this.messageData.version,
          };

          this.inProgress = true;
          MessageRepository.sendMessage(this.messageData.uuid, payload).then(() => {
            this.getMessageDetails();
            Vue.swal({
              icon: "success",
              toast: false,
              position: 'top',
              title: "SUKCES! ",
              text: 'Wiadomość została wysłana!',
              showConfirmButton: false,
              timer: 1500,
            });
            this.inProgress = false;
            this.$router.push({ name: 'Szczegóły wiadomości', params: { id: this.messageData.uuid } });
          }).catch(error => {
            Vue.swal({
              icon: "error",
              position: 'top',
              title: "Wystąpił błąd. Spróbuj jeszcze raz!",
              text: error.response.data.message,
              showConfirmButton: true
            });
            this.inProgress = false;
          })
        }
      });
    },
    changeDraft() {
      if (this.messageData.title === '') {
        Swal.fire({
          title: 'Błąd',
          text: 'Nie możesz zaktualizować wiadomości z pustym tematem.',
          type: 'error',
          confirmButtonText: 'Ok',
        });
        return;
      }

      if (this.messageData.title.length < 10) {
        Swal.fire({
          title: 'Błąd',
          text: 'Tytuł wiadomości musi składać się z minimum 10 znaków.',
          type: 'error',
          confirmButtonText: 'Ok',
        });
        return;
      }

      let payload = {
        title: this.messageData.title,
        content: this.messageData.content,
        label: 'info',
        version: this.messageData.version,
      };

      this.inProgress = true;
      MessageRepository.changeDraft(this.messageData.uuid, payload).then(() => {
        this.getMessageDetails();
        Vue.swal({
          icon: "success",
          toast: false,
          position: 'top',
          title: "SUKCES! ",
          text: 'Wiadomość została zapisana w wersji draft!',
          showConfirmButton: false,
          timer: 1500,
        });
        this.inProgress = false;
      }).catch(error => {
        Vue.swal({
          icon: "error",
          position: 'top',
          title: "Wystąpił błąd. Spróbuj jeszcze raz!",
          text: error.response.data.message,
          showConfirmButton: true
        });
        this.inProgress = false;
      })
    },
    removeDraft() {
      Swal.fire({
        title: "Usuwanie wiadomości",
        text: "Usuniętej wiadomości nie będzie można przywrócić. Czy na pewno powinna być usunięta?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Tak, usuń wiadomość!",
        cancelButtonText: "Anuluj"
      }).then(result => {
        if (result.value) {
          MessageRepository.removeDraft(this.messageData.uuid, this.messageData.version).then(() => {
            Swal.fire("Usunięto wiadomość!", "Wiadomość została poprawnie usunięta.", "success");
            this.$router.push({ name: 'Lista wiadomości', params: { id: this.messageData.uuid } });
          }).catch(error => {
            Vue.swal({
              icon: "error",
              position: 'top',
              title: "Wystąpił błąd. Spróbuj jeszcze raz!",
              text: error.response.data.message,
              showConfirmButton: true
            });
          })
        }
      });
    },
    getMessageDetails() {
      if (this.$route.params.id) {
        MessageRepository.getItem(this.$route.params.id).then((response) => {
          this.messageData = response.data;
          this.preloader = false;
        }).catch(error => {
          this.preloader = false;
          console.log(error);
        })
      }
    },
    getOnlyMessageAttachments() {
      if (this.$route.params.id) {
        MessageRepository.getItem(this.$route.params.id).then((response) => {
          this.messageData.attachments = response.data.attachments;
          this.messageData.version = parseInt(response.data.version);
          this.preloader = false;
        }).catch(error => {
          this.preloader = false;
          console.log(error);
        })
      }
    },
    getOnlyMessageRecipients() {
      if (this.$route.params.id) {
        MessageRepository.getItem(this.$route.params.id).then((response) => {
          this.messageData.recipients = response.data.recipients;
          this.messageData.version = parseInt(response.data.version);
          this.preloader = false;
        }).catch(error => {
          this.preloader = false;
          console.log(error);
        })
      }
    },
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <preloader v-if="preloader"></preloader>

    <template v-if="!preloader">
      <div class="row">
        <div class="col-12">
          <!-- Left sidebar -->
          <div class="email-leftbar card">
            <MessageMenu/>
          </div>
          <!-- End Left sidebar -->

          <!-- Right Sidebar -->
          <div class="email-rightbar mb-3">
            <div class="card">
              <div class="card-body">
                <message-recipient-list :message-data="messageData" presentation-mode="edit"/>
                <hr/>
                <b-form-input
                  maxlength="100"
                  size="lg"
                  v-model="messageData.title"
                  placeholder="Wpisz tytuł wiadomości">
                </b-form-input>
                <div class="mt-2"></div>
                <ckeditor v-model="messageData.content" :editor="editor"></ckeditor>

                <MessageAttachments :message="messageData" presentation-mode="edit"/>

                <button type="button" class="btn btn-secondary waves-effect mt-4" @click="sendMessage">
                  <i class="mdi mdi-send"></i> Wyślij
                </button>

                <button type="button" class="btn btn-success waves-effect mt-4 ml-2" @click="changeDraft">
                  <i class="mdi mdi-content-save"></i> Zapisz jako szkic
                </button>

                <button type="button" class="btn btn-danger waves-effect mt-4 ml-2 float-right" @click="removeDraft">
                  <i class="mdi mdi-trash-can"></i> Usuń
                </button>
              </div>
            </div>
          </div>
          <!-- card -->
        </div>
        <!-- end Col-9 -->

      </div>
    </template>
  </Layout>
</template>
<style>
.ck-editor__editable {
  min-height: 600px !important;
}
</style>
